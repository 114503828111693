import MarkdownFastProgressiveRender from "../markdownFastRender.js";
import MessageOptionBar from "../messageOptionBar.js";
import React, { useState, useEffect, useRef, createRef } from 'react';
function MessageList({chatBoxRef, messages,  introductionMessageLength, dynamicActions}){

    console.log("MessageList.js: ", messages);
    const [autoScroll, setAutoScroll] = useState(true);
    function stopAutoScroll(e) {
        const wheelDirection = e.deltaY < 0 ? 'up' : 'down';
        if (wheelDirection == 'up') {
          setAutoScroll(false);
        } else if (wheelDirection == 'down') {
          const chat = chatBoxRef.current;
          const isScrolledToBottom = chat.scrollHeight - chat.scrollTop - chat.clientHeight <= chat.clientHeight + 1;
          if (isScrolledToBottom) {
            setAutoScroll(true);
          }
        }
    }

    return (
        <>
            {Object.keys(messages).length > 0 && (
                <div ref={chatBoxRef} className={`relative overflow-y-auto ${Object.keys(messages).length > 1 ? 'h-[50vh] min-h-[20em]' : ''}`} onWheel={stopAutoScroll}>
                    {Object.entries(messages).map(([key, message]) => (
                    <div key={key} className={`mr-4 mt-4 p-4 rounded-lg text-base sm:text-lg lg:text-xl text-gray-900 ${message.type === 'ia' ? 'bg-indigo-300' : 'bg-gray-100'}`}>
                        <MarkdownFastProgressiveRender message={message.message} />
                        {Object.keys(messages).length > introductionMessageLength && (
                            <MessageOptionBar 
                                htmlContent={message.message}
                                dynamicActions={dynamicActions}
                            />
                        )}
                    </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default MessageList;