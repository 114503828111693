/* eslint-disable no-restricted-globals */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef, memo, createRef } from 'react';
import ReactDOMServer from "react-dom/server";
import { add, forEach } from "lodash";
import PdfImage from "../../assets/image/pdf.png";
import InputFile from "./InputFile.js";
import MarkdownFastProgressiveRender from "./markdownFastRender.js";
import MessageOptionBar from "./messageOptionBar.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import wowo from "worker-loader!./streamWorker.js";
//import 'katex/dist/katex.min.css';
import DropBox from "./component/dropbox.js"; // Importer le nouveau composant

import ActionsComponent from "./actionComponent.js";
import MessageList from "./component/messageList.js";
import Spinner from "./loading/spinner.js";
//on prend une div
const worker = new wowo();

//tenter une approche avec un tableau de deiv pour les messages et éviter l'update complet de la page

function useAutosizeTextarea(value,messages) {
    const textAreaRef = useRef(null);

    useEffect(() => {
        console.log(messages);
        if(Object.keys(messages).length == 1){
            return textAreaRef.current.style.height = '200px';
        }


        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const { scrollHeight } = textAreaRef.current;
            if (scrollHeight > 270) {
                return textAreaRef.current.style.height = `270px`;
            }
            textAreaRef.current.style.height = `${scrollHeight + 5}px`;
        }
    }, [value,messages]);

    return textAreaRef;
}


function QuestionGenerate({ session, userDetails }) {
    const [userInput, setUserInput] = useState('');
    const chatBoxRef = useRef(null);
    const inputFileRef = createRef();
    const [autoScroll, setAutoScroll] = useState(true);
    const [messages, setMessages] = useState({
        0: { type: 'ia', message: "<h4> Génère des quizs pour mémoriser n’importe quel cours ou sujet en téléchargeant un document (support de cours, notes de cours, article de presse…) ou bien en indiquant le thème de ton choix !</h4>" }
    });
    const [fileUrl, setFileUrl] = useState([]);
    //const textAreaRef = useAutosizeTextarea(userInput,messages);
    const userData = userDetails.data;
    const [userLevelInput, setUserLevelInput] = useState(userData.level);
    const [userSubjectInput, setUserSubjectInput] = useState('');

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    function imageLoaded(e) {
        chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }
    window.imageLoaded = imageLoaded;//for call function for html 

    const SimpleFetch = async (url, type = "POST", data = {}) => {
        const response = await fetch(url, {
            method: type,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
        
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        
          return await response.json();
    }

    worker.onmessage = (event) => {
        let { id, text, baseContent, additionnalData } = event.data;
        if(additionnalData !== undefined){
            additionnalData = JSON.parse(additionnalData);
        }
        setMessagesForStream(text, id, baseContent, additionnalData);
    };

    const setMessagesForStream = (message, id, baseContent, additionnalData) => {
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'ia', message: message, baseContent: baseContent, additionnalData : additionnalData },
        }));
        if (autoScroll) {
            chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        }
    };
    const handleSend = (e) => {
        const params = { 
            "createThread" : Object.keys(messages).length == 1 ? true : false,
            "level" : userLevelInput,
            "subject" : userSubjectInput
        };
        setAutoScroll(true);
        e.preventDefault();
        if (!session.userid) return;

        let html = "";
        forEach(fileUrl, (url) => {
            html += `<img width=72px src="${getUrlForExtension(url)}" onload="window.imageLoaded()" />`;
        });

        const id = Object.keys(messages).length;
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'user', message: `<div>` + userInput + html + "</div>"  },
        }));

        setMessages((prevMessages) => ({
            ...prevMessages,
            [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<><h3>Réponse en cours de génération <Spinner></Spinner></h3></>) },
        }));
       
        worker.postMessage({
            url:"https://ai.kojyto.fr/api/v1/generateQuestion",
            fileUrl,
            userInput,
            userid: session.userid,
            messageId: id + 1,
            params
        });

        setFileUrl([]);
        setUserInput("");
    };


    function stopAutoScroll(e) {
        const wheelDirection = e.deltaY < 0 ? 'up' : 'down';
        if (wheelDirection == 'up') {
            setAutoScroll(false);
        } else if (wheelDirection == 'down') {
            const chat = chatBoxRef.current
            const isScrolledToBottom = chat.scrollHeight - chat.scrollTop  - chat.clientHeight <= chat.clientHeight + 1;
            if (isScrolledToBottom) {
                setAutoScroll(true);
            }
        }
    }

    const removeFileUrl = (urlToRemove) => {
        setFileUrl((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));
    };

    const getUrlForExtension = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return extension === "pdf" ? PdfImage : url;
    };

    const transformResponseToQuizz = async (data) => {
        //fetch("https://ai.kojyto.fr/api/v1/quizz", {})
        setAutoScroll(true);
        chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        const id = Object.keys(messages).length;
        worker.postMessage({url:"https://ai.kojyto.fr/api/v1/generatequestionfromresponse", fileUrl: [], userInput: data.baseContent, userid: session.userid, messageId: id });
        //var rslt = await SimpleFetch("https://ai.kojyto.fr/api/v1/generatequestionfromresponse", "POST", {userid : session.userid, text: response})
    }

    return (
        <div className="h-max-[100vh] flex items-center justify-center bg-gray-50 p-4">
            <div className="w-full max-w-[98%] p-6 bg-white rounded-lg shadow-md">
                <div className="flex flex-col items-center">
                    <div className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-2 text-center">Kojyto quizz 🎓</div>
                </div>
                
                <div>
                    

                    <MessageList 
                        chatBoxRef={chatBoxRef}
                        messages={messages}
                        introductionMessageLength={1}
                        dynamicActions={[]}
                    />

                    <div className="mt-3 w-[80%] m-auto">
                        {fileUrl.map((url, index) => (
                            <div key={index} className="w-20 h-20 rounded relative inline-block ml-2">
                                <svg
                                    onClick={() => removeFileUrl(url)}
                                    className="absolute right-0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="red"
                                    width="24px"
                                    height="24px"
                                >
                                    <path d="M19.414 4.586a2 2 0 00-2.828 0L12 9.172 7.414 4.586a2 2 0 00-2.828 2.828L9.172 12l-4.586 4.586a2 2 0 002.828 2.828L12 14.828l4.586 4.586a2 2 0 002.828-2.828L14.828 12l4.586-4.586a2 2 0 000-2.828z" />
                                </svg>
                                <img className="w-20 h-20 rounded" src={getUrlForExtension(url)} />
                            </div>
                        ))}
                    </div>

                    <div className="mt-4 flex flex-col space-y-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1">
                                
                                <DropBox
                                    onFileIsUploaded={(url) => { setFileUrl((prevUrls) => [...prevUrls, url]); }}
                                    fileUrl={fileUrl}
                                    removeFileUrl={removeFileUrl}
                                />
                            </div>
                            <button
                                onClick={handleSend}
                                className="p-4 bg-theme text-white rounded-lg whitespace-nowrap"
                            >
                                Envoyer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        userDetails: state.userDetails,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators({},
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(QuestionGenerate);
