import React, { useState } from 'react';
import Markdown from '../../Markdown';
const TabsComponent = ({ tabs = [], className = '' }) => {
    if (!tabs.length) {
      return <div className="p-4 text-gray-500">Aucun onglet disponible</div>;
    }
  
    const tabGroupId = `tabs-${Math.random().toString(36).slice(2, 11)}`;
    
    return (
      <div className={className} data-tabs-container>
        {/* Les styles sont maintenant dans une balise style séparée pour être sûr qu'ils sont préservés */}
        <style dangerouslySetInnerHTML={{ __html: `
          [data-tabs-container="${tabGroupId}"] [data-tab-panel] {
            display: none;
          }
          
          ${tabs.map((_, index) => `
            [data-tabs-container="${tabGroupId}"] [data-tab-input="${index}"]:checked ~ [data-tab-panels] [data-tab-panel="${index}"] {
              display: block !important;
            }
            
            [data-tabs-container="${tabGroupId}"] [data-tab-input="${index}"]:checked ~ [data-tab-labels] [data-tab-label="${index}"] {
              border-bottom: 2px solid #3b82f6;
              color: #2563eb;
            }
          `).join('\n')}
        `}} />
  
        <div data-tabs-container={tabGroupId}>
          {/* Radio buttons */}
          {tabs.map((_, index) => (
            <input
              key={`input-${index}`}
              type="radio"
              name={tabGroupId}
              id={`${tabGroupId}-${index}`}
              data-tab-input={index}
              defaultChecked={index === 0}
              style={{ position: 'absolute', opacity: 0 }}
            />
          ))}
  
          {/* Labels */}
          <div data-tab-labels className="flex border-b border-gray-200">
            {tabs.map((tab, index) => (
              <label
                key={`label-${index}`}
                htmlFor={`${tabGroupId}-${index}`}
                data-tab-label={index}
                className="px-4 py-2 text-md font-semibold cursor-pointer text-gray-500 hover:text-gray-700"
              >
                {tab.label}
              </label>
            ))}
          </div>
  
          {/* Panels */}
          <div data-tab-panels className="mt-4">
            {tabs.map((tab, index) => (
              <div 
                key={`panel-${index}`}
                data-tab-panel={index}
                className="rounded-lg"
              >
                <Markdown>{tab.content}</Markdown>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
};
  
export default TabsComponent;
