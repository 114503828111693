// DropBox.js
import React, { useRef } from 'react';
import { simpleReq } from "../../../utils/globalutils.js";
import imageBtnIcone from "../../../assets/image/imageicone.png";
import PdfImage from "../../../assets/image/pdf.png";

function DropBox({ onFileIsUploaded, fileUrl, removeFileUrl }) {
  const fileInput = useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files);
    uploadFiles(files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    uploadFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const uploadFiles = async (files) => {
    for (const file of files) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async (event) => {
      const isHttps = window.location.protocol === "https:" ? true : false;
      let baseUrl = isHttps ? "https://api.kojyto.fr:8125" : "http://api.kojyto.fr:8126";
      const d = await simpleReq(baseUrl + "/upload", file);
      const fileUrl = baseUrl + "/" + d.path;
      onFileIsUploaded(fileUrl);
      fileInput.current.value = '';
    };
  };

  const getUrlForExtension = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    return extension === "pdf" ? PdfImage : url;
  };

  return (
    <>
      {/* Zone de dépôt de fichiers */}
      <div
        className="flex w-full flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-4 bg-gray-100 cursor-pointer h-32"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
      >
        <p className="text-gray-500 text-center">
          Glissez et déposez vos fichiers ici ou cliquez pour sélectionner
        </p>
        <input
          ref={fileInput}
          onChange={handleFileSelect}
          type="file"
          id="fileElem"
          accept="image/*,application/pdf"
          className="hidden"
        />
      </div>
    </>
  );
}

export default DropBox;
