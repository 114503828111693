


var simpleReq = async (url,data) =>{

    return new Promise(function (resolve, reject) {
          fetch(url, {
            //mode:'no-cors',
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: data
          }).then(async response => {
               if (response.ok) {
                    
                    response.json().then(json => resolve(json));
               } else {
                    response.json().then(json => reject(json));
               };
          }).catch(async error => {
               reject(error);
          });
    });
};



export {simpleReq}