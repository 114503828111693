import { useState, useRef } from "react";
import { PlusIcon } from "@heroicons/react/outline";

function ActionsComponent({ actions }) {
    const [showActions, setShowActions] = useState(false);
    return (
        <div className="relative whitespace-nowrap">
            <PlusIcon
                onClick={() => setShowActions(!showActions)}
                className="w-8 text-white bg-theme rounded-full p-1 select-none cursor-pointer"
            />
            {showActions && (
                <div className="absolute top-[-50px] right-[10px]">
                    <ul className="select-none flex items-center space-x-2 bg-white rounded-full px-4 py-2 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)]">
                        {actions.map((element, index) => (
                            <li 
                                key={index}
                                onClick={() => {element.action();setShowActions(!showActions)}} 
                                className="px-3 py-1 text-blue-700 hover:bg-theme hover:text-white rounded-md transition-colors duration-200 cursor-pointer"
                            > 
                                {element.name} 
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default ActionsComponent;