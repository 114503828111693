import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import { parse } from "date-fns";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "react-google-login";
import {
  clearLoginUserDetails,
  fetchLoginUserDetails,
  updateUser,
  updateBasicUserData,
} from "@settingsaction";
import {
  renderHiddenFieldText,
  renderFieldText,
  renderDatePickerWithMMYYLabel,
  classNames,
} from "@basecomponent";
import {
  DATE_FORMAT,
  BASE_IMAGES_URL,
  FACEBOOKAPPID,
  GOOGLEID,
  MAIN_URL,
} from "@constant";

import ContentHeader from "@contentheader";
import ImageCropper from "@imagecropper";
import Loader from "@loader";
import LocaleStrings from "@language";
import { isNull } from "lodash";

const Profile = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  var {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    session,
    userDetails,
    initialValues,
  } = props;
  var [loading, setLoading] = useState(false);
  var imagePreviewUrl = "";

  useEffect(() => {
    props.fetchLoginUserDetails(session);
    if (location?.search) {
      const queryParams = new URLSearchParams(location.search);
      let action = queryParams.get("action");

      if (action === "sociallogin") {
        let socialtype = queryParams.get("socialtype");
        if (socialtype === "twitter") {
          let userid = queryParams.get("userid");
          let email = queryParams.get("email");
          let name = queryParams.get("name");

          responseTwitter({
            socialtype: socialtype,
            userid: userid,
            email: email,
            name: name,
          });
        }
      }
    }
  }, []);

  const onSubmitForm = (data) => {
    var values = { ...data };
    // console.log("values :- ", values);

    values.firstname = values?.firstname.trim();
    values.lastname = values?.lastname.trim();
    values.username = `${values?.firstname} ${values?.lastname}`;
    values.dob = moment(values?.dob).format(DATE_FORMAT);

    // console.log("values final:- ", values);

    setLoading(true);
    props.updateUser(session, values, (res) => {
      // console.log("res :- ", res);
      setLoading(false);
      if (res.success) {
        props.clearLoginUserDetails();
        props.autofill("image", "");
        toast.success(LocaleStrings.profile_setting_form_update_success);
        props.fetchLoginUserDetails(session);
      } else {
        toast.error(LocaleStrings.common_fail_message);
      }
    });
  };

  const handleUnlinkSocialAccount = (type) => {
    // console.log("type :- ", type);
    var values = { userid: initialValues?.userid };
    if (type === "facebook") {
      values.facebook = null;
    } else if (type === "twitter") {
      values.twitter = null;
    } else if (type === "google") {
      values.google = null;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">{LocaleStrings.disconnect}</div>
            <div className="confirmation-subtitle">
              {type === "facebook"
                ? LocaleStrings.profile_setting_facebook_disconnect_confirmation_text
                : type === "twitter"
                ? LocaleStrings.profile_setting_twitter_disconnect_confirmation_text
                : type === "google"
                ? LocaleStrings.profile_setting_google_disconnect_confirmation_text
                : LocaleStrings.profile_setting_social_link_disconnect_confirmation_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  onClose();
                  props.updateBasicUserData(session, values, (res) => {
                    // console.log("res :- ", res);
                    if (res.success == 1) {
                      props.clearLoginUserDetails();
                      toast.success(
                        type === "facebook"
                          ? LocaleStrings.profile_setting_facebook_disconnect_success
                          : type === "twitter"
                          ? LocaleStrings.profile_setting_twitter_disconnect_success
                          : type === "google"
                          ? LocaleStrings.profile_setting_google_disconnect_success
                          : LocaleStrings.profile_setting_social_link_disconnect_success
                      );
                      props.fetchLoginUserDetails(session);
                    } else {
                      toast.error(LocaleStrings.common_fail_message);
                    }
                  });
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });

    // confirmAlert({
    //   title: LocaleStrings.disconnect,
    //   message:
    //     type === "facebook"
    //       ? LocaleStrings.profile_setting_facebook_disconnect_confirmation_text
    //       : type === "twitter"
    //       ? LocaleStrings.profile_setting_twitter_disconnect_confirmation_text
    //       : type === "google"
    //       ? LocaleStrings.profile_setting_google_disconnect_confirmation_text
    //       : LocaleStrings.profile_setting_social_link_disconnect_confirmation_text,
    //   buttons: [
    //     {
    //       label: LocaleStrings.button_yes,
    //       onClick: () => {
    //         props.updateBasicUserData(session, values, (res) => {
    //           // console.log("res :- ", res);
    //           if (res.success == 1) {
    //             props.clearLoginUserDetails();
    //             toast.success(
    //               type === "facebook"
    //                 ? LocaleStrings.profile_setting_facebook_disconnect_success
    //                 : type === "twitter"
    //                 ? LocaleStrings.profile_setting_twitter_disconnect_success
    //                 : type === "google"
    //                 ? LocaleStrings.profile_setting_google_disconnect_success
    //                 : LocaleStrings.profile_setting_social_link_disconnect_success
    //             );
    //             props.fetchLoginUserDetails(session);
    //           } else {
    //             toast.error(LocaleStrings.common_fail_message);
    //           }
    //         });
    //       },
    //     },
    //     {
    //       label: LocaleStrings.button_no,
    //       onClick: () => {},
    //     },
    //   ],
    // });
  };

  const responseFacebook = (obj) => {
    // console.log("Facebook :- ", obj);

    if (obj?.userID) {
      let values = {
        userid: initialValues?.userid,
        facebook: obj.userID,
      };
      // console.log("values :- ", values);

      if (obj.email === initialValues?.email) {
        props.updateBasicUserData(session, values, (res) => {
          // console.log("res :- ", res);
          if (res.success == 1) {
            toast.success(
              LocaleStrings.profile_setting_facebook_connect_success
            );
            props.fetchLoginUserDetails(session);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        toast.error(
          LocaleStrings.profile_setting_facebook_connect_error_email_not_matched
        );
      }
    }
  };

  const handleTwitter = () => {
    var url = `${MAIN_URL}twitter_login/index.php`;

    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("id", "twitterloginid");
    formElement.setAttribute("action", url);
    formElement.setAttribute("target", "_parent");

    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("name", "callfrom");
    hiddenField.setAttribute("value", "profile");
    hiddenField.setAttribute("class", "hidden");
    formElement.appendChild(hiddenField);

    document.body.appendChild(formElement);
    formElement.submit();
  };

  const responseTwitter = (obj) => {
    // console.log("Twitter :- ", obj);

    if (obj?.userid) {
      let values = {
        userid: session?.userid,
        twitter: obj.userid,
      };
      // console.log("values :- ", values);

      if (obj.email === session?.email) {
        props.updateBasicUserData(session, values, (res) => {
          // console.log("res :- ", res);
          navigate("/settings/profile");
          if (res.success == 1) {
            toast.success(
              LocaleStrings.profile_setting_twitter_connect_success
            );
            props.fetchLoginUserDetails(session);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        toast.error(
          LocaleStrings.profile_setting_twitter_connect_error_email_not_matched
        );
      }
    }
  };

  const responseGoogle = (obj) => {
    // console.log("Google :- ", obj);

    if (obj?.profileObj) {
      let values = {
        userid: initialValues?.userid,
        google: obj.profileObj.googleId,
      };
      // console.log("values :- ", values);

      if (obj?.profileObj?.email === initialValues?.email) {
        props.updateBasicUserData(session, values, (res) => {
          // console.log("res :- ", res);
          if (res.success == 1) {
            toast.success(LocaleStrings.profile_setting_google_connect_success);
            props.fetchLoginUserDetails(session);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        toast.error(
          LocaleStrings.profile_setting_google_connect_error_email_not_matched
        );
      }
    }
  };

  const onProfileImageSave = (img) => {
    // console.log("img :- ", img);

    props.autofill(
      "image",
      img == "" && isNull(initialValues?.image) ? null : img
    );
  };

  const onProfileImageChange = (bool) => {};

  if (initialValues?.image)
    imagePreviewUrl = `${BASE_IMAGES_URL}/${initialValues?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`;

  return (
    <>
      <ContentHeader
        customData={{
          section: "settings",
          subsection: "profile",
          breadcrumbs: [
            {
              key: "settings",
              text: LocaleStrings.settings,
              href: "/settings",
            },
            {
              key: "profile",
              text: LocaleStrings.settings_text_contact_details,
            },
          ],
        }}
      />

      <div
        className={classNames(
          userDetails?.data
            ? "grid grid-cols-12"
            : "h-5/6 flex justify-center items-center",
          "content-body"
        )}
      >
        <div className="col-span-12 mb-5 text-2xl 2xs:text-3xl font-normal text-primary">
          {LocaleStrings.profile_setting_text_personal_info}
        </div>
        {userDetails?.data ? (
          <>
            <div className="col-span-12">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="space-y-5">
                  <div className="space-y-1 2xs:space-y-3">
                    <label className="control-label-top col-span-12">
                      {LocaleStrings.profile_setting_form_label_profile}
                    </label>
                    <ImageCropper
                      displaySize={{ width: 170, height: 170 }} // For image display style
                      requiredSize={{ width: 200, height: 200 }} // For image size required validation
                      cropperSize={{ width: 100, height: 100 }} // Cropper display size. Note its add 50px for padding
                      onImageSave={onProfileImageSave}
                      onImageChange={onProfileImageChange}
                      imagepath={imagePreviewUrl}
                      displayText={true}
                      imageType="jpg"
                      className="w-52"
                    />
                    <Field
                      name="image"
                      type="text"
                      component={renderHiddenFieldText}
                    />
                  </div>

                  <div className="grid grid-cols-12 2xs:gap-x-10 gap-y-5">
                    <div className="col-span-12 sm:col-span-6 space-y-1 2xs:space-y-3">
                      <label className="control-label-top">
                        {LocaleStrings.profile_setting_form_label_email}
                      </label>
                      <div className="input-div-disabled-field">
                        {initialValues?.email}
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <Field
                        name="firstname"
                        label={LocaleStrings.profile_setting_form_label_fname}
                        placeholder={
                          LocaleStrings.profile_setting_form_ph_fname
                        }
                        type="text"
                        component={renderFieldText}
                        mandatory="true"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <Field
                        name="lastname"
                        label={LocaleStrings.profile_setting_form_label_lname}
                        placeholder={
                          LocaleStrings.profile_setting_form_ph_lname
                        }
                        type="text"
                        component={renderFieldText}
                        mandatory="true"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <Field
                        name="dob"
                        label={LocaleStrings.profile_setting_form_label_dob}
                        placeholder={LocaleStrings.profile_setting_form_ph_dob}
                        component={renderDatePickerWithMMYYLabel}
                        mandatory="true"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 sm:flex sm:justify-end">
                    <button
                      type="submit"
                      className="theme-button-lg w-full sm:w-auto focus:outline-none sm:col-start-2"
                      disabled={pristine || invalid || submitting || loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_update}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {/* Social accounts */}
            <div className="col-span-12 mt-10">
              <div className="hidden 2xs:grid grid-cols-12 2xs:gap-x-10 gap-y-5">
                {/* Facebook */}
                <div className="col-span-12 sm:col-span-6">
                  <div className="border border-defaultColor rounded-2xl shadow-lg">
                    <div className="px-6 py-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <svg
                            className="w-12 h-12"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <ellipse
                              cx="18.6995"
                              cy="18.577"
                              rx="15.6824"
                              ry="15.6918"
                              fill="url(#paint0_linear_70_900)"
                            ></ellipse>
                            <path
                              d="M24.5398 23.376L25.2364 18.947H20.8785V16.0742C20.8785 14.8622 21.4862 13.6801 23.438 13.6801H25.4206V9.90951C25.4206 9.90951 23.6222 9.61026 21.9035 9.61026C18.3129 9.61026 15.9682 11.732 15.9682 15.5714V18.947H11.9785V23.376H15.9682V34.0833C16.7692 34.2061 17.5886 34.2689 18.4234 34.2689C19.2581 34.2689 20.0775 34.2061 20.8785 34.0833V23.376H24.5398Z"
                              fill="white"
                            ></path>
                            <defs>
                              <linearGradient
                                id="paint0_linear_70_900"
                                x1="18.6995"
                                y1="2.88519"
                                x2="18.6995"
                                y2="34.1758"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#18ACFE"></stop>
                                <stop offset="1" stopColor="#0163E0"></stop>
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>

                        {initialValues?.facebook ? (
                          <div
                            className={classNames(
                              "text-xl 2xs:text-2xl font-normal text-customred capitalize hover:underline cursor-pointer"
                            )}
                            onClick={() =>
                              handleUnlinkSocialAccount("facebook")
                            }
                          >
                            {LocaleStrings.disconnect}
                          </div>
                        ) : (
                          <FacebookLogin
                            appId={FACEBOOKAPPID}
                            // autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                              <div
                                className={classNames(
                                  "text-xl 2xs:text-2xl font-normal text-customgreen capitalize hover:underline cursor-pointer"
                                )}
                                onClick={renderProps.onClick}
                              >
                                {LocaleStrings.connect}
                              </div>
                            )}
                            textButton={LocaleStrings.facebook_signin}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Twitter */}
                <div className="col-span-12 sm:col-span-6">
                  <div className="border border-defaultColor rounded-2xl shadow-lg">
                    <div className="px-6 py-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <svg
                            className="w-12 h-12"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.986 28.6435C8.75101 28.6435 5.73544 27.7026 3.19727 26.0791C5.35226 26.2186 9.15534 25.8846 11.5208 23.6283C7.96234 23.465 6.35753 20.7358 6.1482 19.5695C6.45056 19.6861 7.89257 19.826 8.7066 19.4995C4.61316 18.4731 3.98519 14.8808 4.12474 13.7845C4.89226 14.321 6.19472 14.5076 6.7064 14.4609C2.89206 11.7317 4.26429 7.62625 4.93878 6.73984C7.67609 10.5322 11.7785 12.6621 16.8536 12.7805C16.758 12.3609 16.7074 11.9239 16.7074 11.4751C16.7074 8.25441 19.3107 5.64349 22.522 5.64349C24.1998 5.64349 25.7117 6.35625 26.773 7.49633C27.8942 7.2336 29.5816 6.61856 30.4065 6.0867C29.9907 7.5796 28.6962 8.82498 27.9132 9.28657C27.9197 9.30227 27.9068 9.2708 27.9132 9.28657C28.601 9.18253 30.4621 8.82486 31.1973 8.32605C30.8337 9.16474 29.4613 10.5592 28.335 11.3399C28.5445 20.5816 21.4737 28.6435 11.986 28.6435Z"
                              fill="#47ACDF"
                            ></path>
                          </svg>
                        </div>

                        {initialValues?.twitter ? (
                          <div
                            className={classNames(
                              "text-xl 2xs:text-2xl font-normal text-customred capitalize hover:underline cursor-pointer"
                            )}
                            onClick={() => handleUnlinkSocialAccount("twitter")}
                          >
                            {LocaleStrings.disconnect}
                          </div>
                        ) : (
                          <div
                            className={classNames(
                              "text-xl 2xs:text-2xl font-normal text-customgreen capitalize hover:underline cursor-pointer"
                            )}
                            onClick={handleTwitter}
                          >
                            {LocaleStrings.connect}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Google */}
                <div className="col-span-12 sm:col-span-6">
                  <div className="border border-defaultColor rounded-2xl shadow-lg">
                    <div className="px-6 py-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <svg
                            className="w-12 h-12"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M34.1045 18.9255C34.1045 17.6353 33.9978 16.6938 33.7667 15.7175H18.7422V21.5408H27.5612C27.3835 22.988 26.4234 25.1674 24.2897 26.6319L24.2597 26.8269L29.0102 30.4356L29.3393 30.4678C32.362 27.7304 34.1045 23.7028 34.1045 18.9255Z"
                              fill="#4285F4"
                            ></path>
                            <path
                              d="M18.7413 34.2689C23.0619 34.2689 26.6891 32.874 29.3384 30.4679L24.2888 26.632C22.9375 27.5561 21.1238 28.2012 18.7413 28.2012C14.5096 28.2012 10.918 25.4639 9.63766 21.6804L9.45 21.696L4.5104 25.4447L4.4458 25.6208C7.07728 30.7468 12.4825 34.2689 18.7413 34.2689Z"
                              fill="#34A853"
                            ></path>
                            <path
                              d="M9.6385 21.6805C9.30068 20.7041 9.10517 19.6579 9.10517 18.577C9.10517 17.4959 9.30068 16.4498 9.62073 15.4735L9.61178 15.2655L4.61027 11.4567L4.44663 11.533C3.36207 13.6602 2.73975 16.0489 2.73975 18.577C2.73975 21.1051 3.36207 23.4937 4.44663 25.6208L9.6385 21.6805Z"
                              fill="#FBBC05"
                            ></path>
                            <path
                              d="M18.7414 8.95266C21.7463 8.95266 23.7732 10.2254 24.929 11.2891L29.4452 6.96507C26.6715 4.43695 23.062 2.88519 18.7414 2.88519C12.4826 2.88519 7.07729 6.40711 4.4458 11.5331L9.61992 15.4735C10.918 11.6901 14.5097 8.95266 18.7414 8.95266Z"
                              fill="#EB4335"
                            ></path>
                          </svg>
                        </div>

                        {initialValues?.google ? (
                          <div
                            className={classNames(
                              "text-xl 2xs:text-2xl font-normal text-customred capitalize hover:underline cursor-pointer"
                            )}
                            onClick={() => handleUnlinkSocialAccount("google")}
                          >
                            {LocaleStrings.disconnect}
                          </div>
                        ) : (
                          <GoogleLogin
                            clientId={GOOGLEID}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            render={(renderProps) => (
                              <div
                                className={classNames(
                                  "text-xl 2xs:text-2xl font-normal text-customgreen capitalize hover:underline cursor-pointer"
                                )}
                                onClick={renderProps.onClick}
                              >
                                {LocaleStrings.connect}
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="block 2xs:hidden py-3.5 px-5 border border-defaultColor rounded-2xl shadow-lg divide-y">
                {/* Facebook */}
                <div className="py-2.5 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <svg
                      className="h-12 w-12"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="25"
                        cy="25"
                        rx="21.875"
                        ry="21.875"
                        fill="url(#paint0_linear_195_4175)"
                      />
                      <path
                        d="M33.1464 31.6899L34.1181 25.5158H28.0394V21.5109C28.0394 19.8214 28.887 18.1735 31.6096 18.1735H34.375V12.9172C34.375 12.9172 31.8664 12.5 29.4692 12.5C24.4607 12.5 21.1901 15.4577 21.1901 20.8101V25.5158H15.625V31.6899H21.1901V46.6164C22.3074 46.7874 23.4504 46.875 24.6147 46.875C25.7791 46.875 26.9221 46.7874 28.0394 46.6164V31.6899H33.1464Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_195_4175"
                          x1="25"
                          y1="3.125"
                          x2="25"
                          y2="46.7452"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#18ACFE" />
                          <stop offset="1" stopColor="#0163E0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>

                  {initialValues?.facebook ? (
                    <div
                      className={classNames(
                        "text-xl 2xs:text-2xl font-normal text-customred capitalize hover:underline cursor-pointer"
                      )}
                      onClick={() => handleUnlinkSocialAccount("facebook")}
                    >
                      {LocaleStrings.disconnect}
                    </div>
                  ) : (
                    <FacebookLogin
                      appId={FACEBOOKAPPID}
                      // autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <div
                          className={classNames(
                            "text-xl 2xs:text-2xl font-normal text-customgreen capitalize hover:underline cursor-pointer"
                          )}
                          onClick={renderProps.onClick}
                        >
                          {LocaleStrings.connect}
                        </div>
                      )}
                      textButton={LocaleStrings.facebook_signin}
                    />
                  )}
                </div>

                {/* Twitter */}
                <div className="py-2.5 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <svg
                      className="w-12 h-12"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.986 28.6435C8.75101 28.6435 5.73544 27.7026 3.19727 26.0791C5.35226 26.2186 9.15534 25.8846 11.5208 23.6283C7.96234 23.465 6.35753 20.7358 6.1482 19.5695C6.45056 19.6861 7.89257 19.826 8.7066 19.4995C4.61316 18.4731 3.98519 14.8808 4.12474 13.7845C4.89226 14.321 6.19472 14.5076 6.7064 14.4609C2.89206 11.7317 4.26429 7.62625 4.93878 6.73984C7.67609 10.5322 11.7785 12.6621 16.8536 12.7805C16.758 12.3609 16.7074 11.9239 16.7074 11.4751C16.7074 8.25441 19.3107 5.64349 22.522 5.64349C24.1998 5.64349 25.7117 6.35625 26.773 7.49633C27.8942 7.2336 29.5816 6.61856 30.4065 6.0867C29.9907 7.5796 28.6962 8.82498 27.9132 9.28657C27.9197 9.30227 27.9068 9.2708 27.9132 9.28657C28.601 9.18253 30.4621 8.82486 31.1973 8.32605C30.8337 9.16474 29.4613 10.5592 28.335 11.3399C28.5445 20.5816 21.4737 28.6435 11.986 28.6435Z"
                        fill="#47ACDF"
                      ></path>
                    </svg>
                  </div>

                  {initialValues?.twitter ? (
                    <div
                      className={classNames(
                        "text-xl 2xs:text-2xl font-normal text-customred capitalize hover:underline cursor-pointer"
                      )}
                      onClick={() => handleUnlinkSocialAccount("twitter")}
                    >
                      {LocaleStrings.disconnect}
                    </div>
                  ) : (
                    <div
                      className={classNames(
                        "text-xl 2xs:text-2xl font-normal text-customgreen capitalize hover:underline cursor-pointer"
                      )}
                      onClick={handleTwitter}
                    >
                      {LocaleStrings.connect}
                    </div>
                  )}
                </div>

                {/* Google */}
                <div className="py-2.5 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <svg
                      className="w-12 h-12"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.1045 18.9255C34.1045 17.6353 33.9978 16.6938 33.7667 15.7175H18.7422V21.5408H27.5612C27.3835 22.988 26.4234 25.1674 24.2897 26.6319L24.2597 26.8269L29.0102 30.4356L29.3393 30.4678C32.362 27.7304 34.1045 23.7028 34.1045 18.9255Z"
                        fill="#4285F4"
                      ></path>
                      <path
                        d="M18.7413 34.2689C23.0619 34.2689 26.6891 32.874 29.3384 30.4679L24.2888 26.632C22.9375 27.5561 21.1238 28.2012 18.7413 28.2012C14.5096 28.2012 10.918 25.4639 9.63766 21.6804L9.45 21.696L4.5104 25.4447L4.4458 25.6208C7.07728 30.7468 12.4825 34.2689 18.7413 34.2689Z"
                        fill="#34A853"
                      ></path>
                      <path
                        d="M9.6385 21.6805C9.30068 20.7041 9.10517 19.6579 9.10517 18.577C9.10517 17.4959 9.30068 16.4498 9.62073 15.4735L9.61178 15.2655L4.61027 11.4567L4.44663 11.533C3.36207 13.6602 2.73975 16.0489 2.73975 18.577C2.73975 21.1051 3.36207 23.4937 4.44663 25.6208L9.6385 21.6805Z"
                        fill="#FBBC05"
                      ></path>
                      <path
                        d="M18.7414 8.95266C21.7463 8.95266 23.7732 10.2254 24.929 11.2891L29.4452 6.96507C26.6715 4.43695 23.062 2.88519 18.7414 2.88519C12.4826 2.88519 7.07729 6.40711 4.4458 11.5331L9.61992 15.4735C10.918 11.6901 14.5097 8.95266 18.7414 8.95266Z"
                        fill="#EB4335"
                      ></path>
                    </svg>
                  </div>

                  {initialValues?.google ? (
                    <div
                      className={classNames(
                        "text-xl 2xs:text-2xl font-normal text-customred capitalize hover:underline cursor-pointer"
                      )}
                      onClick={() => handleUnlinkSocialAccount("google")}
                    >
                      {LocaleStrings.disconnect}
                    </div>
                  ) : (
                    <GoogleLogin
                      clientId={GOOGLEID}
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      render={(renderProps) => (
                        <div
                          className={classNames(
                            "text-xl 2xs:text-2xl font-normal text-customgreen capitalize hover:underline cursor-pointer"
                          )}
                          onClick={renderProps.onClick}
                        >
                          {LocaleStrings.connect}
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var firstname = values["firstname"];
  var lastname = values["lastname"];
  var dob = values["dob"];

  if (!firstname || firstname.trim() === "") {
    errors["firstname"] = LocaleStrings.required;
  }
  if (!lastname || lastname.trim() === "") {
    errors["lastname"] = LocaleStrings.required;
  }
  if (!dob) {
    errors["dob"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var init = {};
  if (state?.userDetails?.data) {
    init = state?.userDetails?.data;
    if (state.userDetails?.data?.dob) {
      var newDob = parse(
        moment(state.userDetails?.data?.dob).format("DD/MM/YYYY"),
        "dd/MM/yyyy",
        new Date()
      );
      init.dob = newDob;
    }
  }

  return {
    session: state.session,
    initialValues: init,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearLoginUserDetails,
      fetchLoginUserDetails,
      updateUser,
      updateBasicUserData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ProfileForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Profile)
);
