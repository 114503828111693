
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef } from 'react';
import {simpleReq} from "../../utils/globalutils.js"
import imageBtnIcone from "../../assets/image/imageicone.png";
function InputFile({onFileIsUploaded,resetFileUpload}){

    const fileInput = useRef(null);
    const onClickFileBtn = (evt) => {
        fileInput.current.click();
    }

    var onFileRequestUpload = async () => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(fileInput.current.files[0]);
        fileReader.onload = async (event) => {
            var isHttps = window.location.protocol == "https:" ? true : false
            var baseUrl = isHttps == true ? "https://api.kojyto.fr:8125" : "http://api.kojyto.fr:8126"
            var d = await simpleReq(baseUrl +"/upload", fileInput.current.files[0]);
            baseUrl += "/" + d.path;
            onFileIsUploaded(baseUrl);
            fileInput.current.value = '';
        }
    }
    return (
        <>
            <input
            ref={fileInput}
            onChange={onFileRequestUpload}
            type="file"
            id="fileElem"
            accept="image/*,application/pdf"
            style={{display:"none"}} />
            <button id="fileSelect" onClick={onClickFileBtn} onMouseDown={(e) => {e.preventDefault()}} type="button" className="size-8"><img width={"32px"} src={imageBtnIcone} /></button>
            
        </>
    )
}

export default InputFile;