import React from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkMathPlugin from 'remark-math';
import { BlockMath, InlineMath } from 'react-katex';
import rehypeRaw from "rehype-raw";
import rehypeKatex from 'rehype-katex'
import rehypeHighlight from 'rehype-highlight';
import 'katex/dist/katex.min.css';

const _mapProps = (props) => ({
  ...props,
  escapeHtml: false,
  remarkPlugins: [
    RemarkMathPlugin,
  ],
  rehypePlugins:[rehypeRaw,rehypeKatex,rehypeHighlight],
  renderers: {
    ...props.renderers,
    math: ({ value }) => <BlockMath>{value}</BlockMath>,
    inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>
  },
  components: {
    ...props.components,
    li: ({children}) => <li className='mt-4'>{children}</li>,
  }
});

const Markdown = (props) => {
    console.log(props)
    console.log(_mapProps(props))
    return <ReactMarkdown {..._mapProps(props)} />;
}

export default Markdown;