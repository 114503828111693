import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';

const OptionsBar = ({ htmlContent, dynamicActions }) => {
    const handleCopy = async () => {
        try {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = htmlContent;

            const processNode = (node) => {
                if (node.nodeType === Node.TEXT_NODE) {
                    return node.textContent;
                }
                
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const tag = node.tagName.toLowerCase();

                    // Traitement spécial pour les éléments KaTeX
                    if (node.classList.contains('katex-display') || node.classList.contains('katex')) {
                        const texSource = node.querySelector('.katex-mathml annotation[encoding="application/x-tex"]');
                        if (texSource) {
                            const isBlock = node.classList.contains('katex-display');
                            return isBlock ? `\n$$${texSource.textContent}$$\n` : `$${texSource.textContent}$`;
                        }
                        // Si on ne trouve pas la source LaTeX, on retourne le texte rendu
                        return node.textContent;
                    }

                    let content = '';
                    for (const childNode of node.childNodes) {
                        content += processNode(childNode);
                    }

                    // Ajouter des sauts de ligne pour certains éléments
                    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ul', 'ol'].includes(tag)) {
                        content = `\n${content.trim()}\n`;
                    } else if (tag === 'li') {
                        content = `\n• ${content.trim()}`;
                    } else if (tag === 'br') {
                        content = '\n';
                    }

                    return content;
                }

                return '';
            };

            let plainText = processNode(tempElement);

            // Nettoyer le texte final
            plainText = plainText
                .replace(/\n+/g, '\n')  // Limiter à un seul saut de ligne consécutif
                .replace(/^\s+/gm, '')  // Supprimer les espaces en début de ligne
                .trim();  // Supprimer les espaces au début et à la fin du texte

            await navigator.clipboard.writeText(plainText);
            console.log('Text copied to clipboard');
            // Optionnel : Afficher un message de succès à l'utilisateur
        } catch (err) {
            console.error('Failed to copy text: ', err);
            // Optionnel : Afficher un message d'erreur à l'utilisateur
        }
    };

    return (
        <div className="flex items-center space-x-2 p-2 bg-gray-100 rounded-md">
            <button 
                onClick={handleCopy}
                className="flex items-center space-x-1 text-gray-600 hover:text-gray-800"
            >
                <DocumentDuplicateIcon className="w-4 h-4" />
                <span className="text-sm">Copy</span>
            </button>

            {dynamicActions.map((element, index) => (
                element.cond && (
                    <button 
                        key={index}
                        onClick={(e) => {e.preventDefault(); element.action()}} 
                        className={"flex items-center space-x-1 text-gray-600 hover:text-gray-800 " + element.color}
                    > 
                        <DocumentDuplicateIcon className="w-4 h-4" />
                        <span className="text-sm">{element.name}</span>
                    </button>
                )
            ))}
           
        </div>
    );
};

export default OptionsBar;

//faire faire de la géométrie avec tikz ?