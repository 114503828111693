import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { fetchUserUpcomingExams } from "@dashboardaction";
import { selectedClassChapter } from "@classesaction";
import { classNames } from "@basecomponent";
import { convertDateIntoLocal, LOCAL_TO_GMT_TIME } from "@constant";
import Pagination from "@pagination";
import LocaleStrings from "@language";
import blankImg from "../../../assets/image/blank_upcoming_examD.svg";

const UpcomingExam = (props) => {
  const navigate = useNavigate();
  var { perPageList, session, userUpcomingExams } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);

  const navigateChapter = (item) => {
    // console.log("item :- ", item);

    props.selectedClassChapter(item.programchapters_by_chapteridfk);
    navigate(`/myspace/${item.classidfk}/${item.chapteridfk}`);
  };

  const paginationCallback = (pageNum) => {
    //setPageLoading(true);
    /*props.fetchUserUpcomingExams(
      session,
      pageNum,
      perPageList,
      {
        userid: session?.userid,
        today: moment(LOCAL_TO_GMT_TIME).format("YYYY-MM-DD"),
      },
      (res) => {
        setPageLoading(false);
        setPageNumber(pageNum);
      }
    );*/
    //setPageLoading(false);
  };

  const renderUpcomingExams = () => {
    return _.map(userUpcomingExams?.data, (item, index) => {
      var completedPercentage =
        item?.programchapters_by_chapteridfk?.totalquestionsets > 0
          ? (parseInt(item?.programchapters_by_chapteridfk?.practicedsets) /
              parseInt(
                item?.programchapters_by_chapteridfk?.totalquestionsets
              )) *
            100
          : 0;
      return (
        <tr key={`exams_key_${index}`}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="space-y-1">
              <div className="capitalize text-xl font-normal text-primary">
                {item?.classprograms_by_classidfk?.programname}
              </div>
              <div className="capitalize text-xl font-normal text-secondary">
                {item?.programchapters_by_chapteridfk?.chaptername}
              </div>
              <div className="pt-2 space-y-1">
                <div className="overflow-hidden h-1.5 flex rounded bg-gray-300 w-9/12">
                  <div
                    style={{
                      width: `${completedPercentage}%`,
                      backgroundColor:
                        item?.classprograms_by_classidfk
                          ?.master_colors_by_coloridfk?.code,
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-theme"
                  />
                </div>
                <span className="text-lg font-normal text-secondary">
                  {item?.programchapters_by_chapteridfk?.practicedsets ?? 0} of{" "}
                  {item?.programchapters_by_chapteridfk?.totalquestionsets ?? 0}{" "}
                  completed
                </span>
              </div>
            </div>
          </td>
          <td className="tabledata">
            {convertDateIntoLocal(
              moment(item?.examdate).subtract(1, "days")
            ).format("MMM DD, YYYY")}
          </td>
          <td className="tabledata">
            {convertDateIntoLocal(item?.examdate).format("MMM DD, YYYY")}
          </td>
          <td className="px-6 py-4">
            <button
              type="button"
              className="default-button-md capitalize"
              onClick={() => navigateChapter(item)}
            >
              {LocaleStrings.continue}
            </button>
          </td>
        </tr>
      );
    });
  };

  const renderUpcomingExamsMobile = () => {
    return _.map(userUpcomingExams?.data, (item, index) => {
      var completedPercentage =
        item?.programchapters_by_chapteridfk?.totalquestionsets > 0
          ? (parseInt(item?.programchapters_by_chapteridfk?.practicedsets) /
              parseInt(
                item?.programchapters_by_chapteridfk?.totalquestionsets
              )) *
            100
          : 0;
      return (
        <li
          key={`exams_key_${index}`}
          className={classNames("px-3 py-2 cursor-pointer")}
          onClick={() => navigateChapter(item)}
        >
          <div className="">
            <span className="text-xl font-medium text-primary">
              {item?.programchapters_by_chapteridfk?.chaptername}
            </span>
            <span className="text-xl font-medium text-secondary">
              {" "}
              - {convertDateIntoLocal(item?.examdate).format("MMM DD, YYYY")}
            </span>
          </div>
          <div className="pt-2 space-y-1">
            <div className="overflow-hidden h-1.5 flex rounded bg-gray-300 w-9/12">
              <div
                style={{
                  width: `${completedPercentage}%`,
                  backgroundColor:
                    item?.classprograms_by_classidfk?.master_colors_by_coloridfk
                      ?.code,
                }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-theme"
              />
            </div>
            <span className="text-lg font-normal text-secondary">
              {item?.programchapters_by_chapteridfk?.practicedsets ?? 0} of{" "}
              {item?.programchapters_by_chapteridfk?.totalquestionsets ?? 0}{" "}
              completed
            </span>
          </div>
        </li>
      );
    });
  };

  // console.log("userUpcomingExams :- ", userUpcomingExams);

  return (
    <>
      <div className="text-2xl sm:text-3xl font-semibold text-primary capitalize mb-8">
        {LocaleStrings.dashboard_text_upcoming_exams}
      </div>
      {userUpcomingExams?.count > 0 ? (
        <div className="flex flex-col">
          {/* For tab and desktop */}
          <div className="hidden 2xs:block shadow-lg border rounded-xl overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="border-b-2">
                <tr>
                  <th scope="col" className="tableheader w-2/4">
                    {LocaleStrings.dashboard_th_chapter_name}
                  </th>
                  <th scope="col" className="tableheader">
                    {LocaleStrings.dashboard_th_deadline}
                  </th>
                  <th scope="col" className="tableheader">
                    {LocaleStrings.dashboard_th_exam_date}
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">{renderUpcomingExams()}</tbody>
            </table>
          </div>

          {/* For mobile */}
          <div className="bg-white block 2xs:hidden shadow-lg border rounded-xl overflow-hidden">
            <ul role="list" className="divide-y divide-gray-200">
              {renderUpcomingExamsMobile()}
            </ul>
          </div>

          {/* Pagination */}
          {userUpcomingExams?.count > perPageList ? (
            <Pagination
              activePage={pageNumber}
              itemsCountPerPage={perPageList}
              totalItemsCount={userUpcomingExams?.count}
              onChange={paginationCallback}
              pageLoading={pageLoading}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="custom-item-center max-w-full mx-auto shadow-lg rounded-xl border h-60 md:h-26rem p-4">
          <div className="text-center">
            <img className="inline" src={blankImg} />
            <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
              {LocaleStrings.dashboard_text_no_upcoming_exams}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    userUpcomingExams: state.userUpcomingExams,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { selectedClassChapter },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingExam);
