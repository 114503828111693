import React from 'react';
import { usePopup } from './popupcontext';

const Popup = () => {
  const { isPopupVisible, popupContent, hidePopup } = usePopup();

  if (!isPopupVisible) return null;

  return (
    <div className="z-[1000] absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 backdrop-blur text-right">
      <div className="overflow-y-auto overflow-x-hidden max-h-[100vh] relative lg:w-[65%] sm:w-full lg:left-[130px] xs:left-[0px] bg-white p-4 rounded shadow-lg">
        <button onClick={hidePopup} className="p-2 bg-red-500 text-white rounded sticky left-[0px] right-[0px] top-[0px]">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24px"
            height="24px"
            >
            <path
                d="M19.414 4.586a2 2 0 00-2.828 0L12 9.172 7.414 4.586a2 2 0 00-2.828 2.828L9.172 12l-4.586 4.586a2 2 0 002.828 2.828L12 14.828l4.586 4.586a2 2 0 002.828-2.828L14.828 12l4.586-4.586a2 2 0 000-2.828z"
            />
            </svg>
        </button>
        <div className='text-left'>
            {popupContent}
        </div>
      </div>
      
    </div>
  );
};

export default Popup;