import React, { useState, useEffect, useRef, memo, createRef } from 'react';

//WARNING: this is implementation for render markdown in a fast and progressive way with streaming response
//for show markdown in one time use react-markdown

const splitHtmlIntoElements = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return Array.from(doc.body.children).map(child => child.outerHTML);
};

const updateNodeRecursively = (oldNode, newNode) => {
    if (!oldNode || !newNode) return false;
    if (oldNode.isEqualNode(newNode)) return false;

    // Si les types de nœuds sont différents, remplacer complètement
    if (oldNode.nodeType !== newNode.nodeType || oldNode.nodeName !== newNode.nodeName) {
        if (oldNode.parentNode) {
            oldNode.parentNode.replaceChild(newNode.cloneNode(true), oldNode);
            return true;
        }
        return false;
    }

    // Optimisation pour les nœuds texte
    if (oldNode.nodeType === Node.TEXT_NODE) {
        if (oldNode.nodeValue !== newNode.nodeValue) {
            oldNode.nodeValue = newNode.nodeValue;
            return true;
        }
        return false;
    }

    // attribute
    let attributesChanged = false;
    const oldAttributes = oldNode.attributes;
    const newAttributes = newNode.attributes;

    if (oldAttributes && newAttributes) {
        const oldAttributesMap = new Map();
        for (let i = 0; i < oldAttributes.length; i++) {
            oldAttributesMap.set(oldAttributes[i].name, oldAttributes[i].value);
        }

        for (let i = 0; i < newAttributes.length; i++) {
            const newAttr = newAttributes[i];
            const oldAttrValue = oldAttributesMap.get(newAttr.name);
            if (oldAttrValue !== newAttr.value) {
                oldNode.setAttribute(newAttr.name, newAttr.value);
                attributesChanged = true;
            }
            oldAttributesMap.delete(newAttr.name);
        }

        // Supprimer les attributs qui n'existent plus
        for (let name of oldAttributesMap.keys()) {
            oldNode.removeAttribute(name);
            attributesChanged = true;
        }
    }

    // Compare and update
    let childrenChanged = false;
    const oldChildren = oldNode.childNodes;
    const newChildren = newNode.childNodes;
    const maxLength = Math.max(oldChildren.length, newChildren.length);

    for (let i = 0; i < maxLength; i++) {
        if (i >= oldChildren.length) {
            if (newChildren[i]) {
                oldNode.appendChild(newChildren[i].cloneNode(true));
                childrenChanged = true;
            }
        } else if (i >= newChildren.length) {
            oldNode.removeChild(oldChildren[i]);
            childrenChanged = true;
            i--;
        } else {
            childrenChanged = updateNodeRecursively(oldChildren[i], newChildren[i]) || childrenChanged;
        }
    }

    return attributesChanged || childrenChanged;
};

const HtmlElement = memo(({ html }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            const parser = new DOMParser();
            const tempDiv = parser.parseFromString(html, 'text/html').body;
            
            if (tempDiv.firstChild) {
                if (containerRef.current.firstChild) {
                    updateNodeRecursively(containerRef.current.firstChild, tempDiv.firstChild);
                } else {
                    containerRef.current.appendChild(tempDiv.firstChild.cloneNode(true));
                }
            } else {
                console.warn('Invalid HTML content:', html);
                containerRef.current.innerHTML = '';
            }
        }
    }, [html]);

    return <div ref={containerRef} />;
});


const MarkdownFastProgressiveRender = memo(({ message }) => {
    const [elements, setElements] = useState([]);
  
    useEffect(() => {
        const newElements = splitHtmlIntoElements(message);
        setElements(newElements);
    }, [message]);

    return (
        <div className="markdown overflow-x-none">
            {elements.map((element, index) => (
                <HtmlElement key={index} html={element} />
            ))}
        </div>
    );
});

export default MarkdownFastProgressiveRender;