import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";


import { Provider } from "react-redux";
import { PopupProvider } from './popup-fragment/popupcontext';
import Popup from './popup-fragment/Popup';
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./build.css";




ReactDOM.render(
  <React.StrictMode>
    <HashRouter >
      <Provider store={store}>
        <PopupProvider>
          <Popup />
          <App />
        </PopupProvider>
      </Provider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
