/* eslint-disable no-restricted-globals */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef, memo, createRef } from 'react';
import { forEach } from "lodash";
import PdfImage from "../../assets/image/pdf.png";
import InputFile from "./InputFile.js";
import MarkdownFastProgressiveRender from "./markdownFastRender.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import wowo from "worker-loader!./streamWorker.js";
//import 'katex/dist/katex.min.css';
import ActionsComponent from "./actionComponent.js";
//on prend une div
const worker = new wowo();

//tenter une approche avec un tableau de deiv pour les messages et éviter l'update complet de la page

function useAutosizeTextarea(value,messages) {
    const textAreaRef = useRef(null);

    useEffect(() => {
        if(Object.keys(messages).length == 1){
            return textAreaRef.current.style.height = '200px';
        }

        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const { scrollHeight } = textAreaRef.current;
            if (scrollHeight > 270) {
                return textAreaRef.current.style.height = `270px`;
            }
            textAreaRef.current.style.height = `${scrollHeight + 5}px`;
        }
    }, [value, messages]);

    return textAreaRef;
}




function MultipleChoiceAssessment({ session }) {
    const [userInput, setUserInput] = useState('');
    const [langage, setLangage] = useState('');
    const chatBoxRef = useRef(null);
    const inputFileRef = createRef();
    const [autoScroll, setAutoScroll] = useState(true);
    const [messages, setMessages] = useState({0: { type: 'ia', message: "<h4>Traduis n’importe quel texte dans n’importe quelle langue ! Tu peux aussi t’en servir pour le latin ou le grec !</h4>" }});
    const textAreaRef = useAutosizeTextarea(userInput,messages);
    const lastScrollTop = useRef(0);
    const [fileUrl, setFileUrl] = useState([]);
    const lastWheelDirection = useRef(null);
    const introductionMessageLength = 1;

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleInputLangageChange = (e) => {
        setLangage(e.target.value);
    };

    function imageLoaded(e) {
        chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }
    window.imageLoaded = imageLoaded;//for call function for html 

    worker.onmessage = (event) => {
        const { id, text } = event.data;
        setMessagesForStream(text, id);
    };

    
    const setMessagesForStream = (message, id) => {
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'ia', message: message },
        }));

        if (autoScroll) {
            chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        }
    };
    const handleSend = (e) => {
        const params = {"langage" : langage, "createThread" : messages.length == introductionMessageLength ? true : false};
        setAutoScroll(true);
        e.preventDefault();
        if (!session.userid) return;

        let html = "";
        forEach(fileUrl, (url) => {
            html += `<img src="${getUrlForExtension(url)}" onload="window.imageLoaded()" />`;
        });

        const id = Object.keys(messages).length;
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'user', message: `<div>` + userInput + html + "</div>"  },
        }));
       
        
        worker.postMessage({
            url:"https://ai.kojyto.fr/api/v1/traductor",
            fileUrl: fileUrl,
            userInput: userInput,
            userid: session.userid,
            messageId: id + 1,
            params: params
        });

        setFileUrl([]);
        setUserInput("");
    };

    function stopAutoScroll(e) {
        const wheelDirection = e.deltaY < 0 ? 'up' : 'down';
        if (wheelDirection == 'up') {
            setAutoScroll(false);
        } else if (wheelDirection == 'down') {
            const chat = chatBoxRef.current
            const isScrolledToBottom = chat.scrollHeight - chat.scrollTop  - chat.clientHeight <= chat.clientHeight + 1;
            if (isScrolledToBottom) {
                setAutoScroll(true);
            }
        }
    }

    const removeFileUrl = (urlToRemove) => {
        setFileUrl((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));
    };

    const getUrlForExtension = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return extension === "pdf" ? PdfImage : url;
    };
    
    return (
        <div className="h-max-[100vh] flex items-center justify-center bg-gray-50 p-4">
            <div className="w-full max-w-[98%] p-6 bg-white rounded-lg shadow-md">
                <div className="flex flex-col items-center">
                    <div className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-2 text-center">Kojyto Traducteur 🎓</div>
                </div>
                <div>
                    {Object.keys(messages).length > 0 && (
                        <div ref={chatBoxRef} className={`relative overflow-y-auto ${Object.keys(messages).length > introductionMessageLength ? 'h-[50vh] min-h-[20em]' : ''}`} onWheel={stopAutoScroll} onClick={() => setAutoScroll(false)}>
                            {Object.entries(messages).map(([key, message]) => (
                                <div key={key} className={`mr-4 mt-4 p-4 rounded-lg text-base sm:text-lg lg:text-xl text-gray-900 ${message.type === 'ia' ? 'bg-indigo-300' : 'bg-gray-100'}`}>
                                    <MarkdownFastProgressiveRender message={message.message} />
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="mt-3 w-[80%] m-auto">
                        {fileUrl.map((url, index) => (
                            <div key={index} className="w-20 h-20 rounded relative inline-block ml-2">
                                <svg
                                    onClick={() => removeFileUrl(url)}
                                    className="absolute right-0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="red"
                                    width="24px"
                                    height="24px"
                                >
                                    <path d="M19.414 4.586a2 2 0 00-2.828 0L12 9.172 7.414 4.586a2 2 0 00-2.828 2.828L9.172 12l-4.586 4.586a2 2 0 002.828 2.828L12 14.828l4.586 4.586a2 2 0 002.828-2.828L14.828 12l4.586-4.586a2 2 0 000-2.828z" />
                                </svg>
                                <img className="w-20 h-20 rounded" src={getUrlForExtension(url)} />
                            </div>
                        ))}
                    </div>

                    <div className="mt-4 flex flex-col space-y-4">
                        <div className="flex items-center space-x-4">
                            <div className="w-12 h-10 flex-shrink-0"></div> {/* Ajusté la largeur à w-12 */}
                            <input 
                                placeholder="Précisez la langue cible pour la traduction (ex. : Français, Anglais, Espagnol)." 
                                className="flex-1 p-4 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500" 
                                type="text"
                                value={langage}
                                onChange={handleInputLangageChange}
                            />
                            <div className="w-[5.3rem] flex-shrink-0"></div> {/* Ajouté flex-shrink-0 */}
                        </div>

                        <div className="flex items-center space-x-4">
                            <InputFile
                                ref={inputFileRef}
                                onFileIsUploaded={(url) => { setFileUrl((prevUrls) => [...prevUrls, url]); }}
                                resetFileUpload={() => { setFileUrl([]); }}
                            />
                            <div className="flex-1">
                                <textarea
                                    ref={textAreaRef}
                                    className="w-full p-4 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    placeholder="Ecris le texte à traduire ou bien télécharge un fichier pdf ou une image"
                                    value={userInput}
                                    onChange={handleInputChange}
                                    rows={1}
                                />
                            </div>
                            <button
                                onClick={handleSend}
                                className="p-4 bg-theme text-white rounded-lg whitespace-nowrap"
                            >
                                Envoyer
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators({},
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceAssessment);
